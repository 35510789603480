import { useState, useEffect } from "react"
import BeatLoader from "react-spinners/BeatLoader";

import { ReactComponent as NextPhotoIndicator } from "../data/svg/arrow.svg"
import images from "../data/context/gallery-images.json";

function Modal({ modalState, setModalState }) {
  const [modalLoading, setModalLoading] = useState(true);

  useEffect(() => {
    if (modalState.image.defaultSize) {
      setModalLoading(true);
      const img = new Image();
      img.src = modalState.image.defaultSize;
      img.onload = () => {
        setModalLoading(false);
      };
    }
  }, [modalState.image]);

  const removeModal = () => {
    setModalState({
      active: false,
      image: null,
    });
    document.body.style.overflow = "auto";
  }

  const setPrevPhoto = (e) => {
    e.stopPropagation();
    var newPhotoIndex = images.indexOf(modalState.image) - 1;
    if (newPhotoIndex < 0) {
      newPhotoIndex = images.length - 1; 
    }

    setModalState({
      active: true,
      image: images[newPhotoIndex],
    });
  }

  const setNextPhoto = (e) => {
    e.stopPropagation();
    var newPhotoIndex = images.indexOf(modalState.image) + 1;
    if (newPhotoIndex > images.length - 1) {
      newPhotoIndex = 0;
    }

    setModalState({
      active: true,
      image: images[newPhotoIndex],
    });
  }

  return (
    <div 
      className="modal" 
      onClick={removeModal}>
      <button 
        className="img-back" 
        onClick={(e) => setPrevPhoto(e)}
        >
          <NextPhotoIndicator/>
      </button>

      <img 
        src={modalState.image.defaultSize}
        onClick={(e) => {e.stopPropagation()}}
        style={{display: modalLoading ? "none" : "block"}}
        alt=""/>

      {modalLoading && <BeatLoader color="#e4e4e4"/>}

      <button 
        className="img-forward" 
        onClick={(e) => setNextPhoto(e)}
        >
          <NextPhotoIndicator style={{ transform: 'rotate(180deg)' }}/>
        </button>
    </div>
  )
}

function GalleryPage() {
  const [scroll, setScroll] = useState(false);
  const [modalState, setModalState] = useState({
    active: false,
    image: null,
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const historyPage = document.getElementById("gallery");
      const historyPosition = historyPage.offsetTop;
      const windowHeight = window.innerHeight;
      const activationOffset = 80;

      const scrollCheck = (windowHeight + window.scrollY) > (historyPosition + activationOffset);
      setScroll(scrollCheck);
    });
  })

  const openModal = (image) => {
    setModalState({
      active: true,
      image: image,
    });
    document.body.style.overflow = "hidden";
  }

  return (
    <>
      <div className={scroll ? "gallery-page active" : "gallery-page"} id="gallery">
        <h2>Galerija</h2>

        <div className="image-container" style={{display: "flex"}}>
          {images.map( (image, key) => {
            return (
              <div className="image-wrapper" key={key}>
                <img src={image.compressed} onClick={() => openModal(image)} alt={key} />
              </div>
            )
          })}
        </div>

        {modalState.active && <Modal modalState={modalState} setModalState={setModalState}/>}

      </div>
    </>
  )
}

export default GalleryPage;