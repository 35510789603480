import React from "react";
import { Link } from "react-scroll";
import LogoImage from "../data/svg/logo.svg";

const backgroundImage = "/images/background.jpg";

function HomePage() {
  const SCROLL_SPEED = 800;
  const backgroundStyle ={
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`
  }

  return (
    <div className="homepage" style={backgroundStyle}>
      <img src={LogoImage} alt="logo"></img>
      <Link 
        activeClass="active"
        to="aboutpage"
        spy={true}
        smooth={true}
        duration={SCROLL_SPEED}
        >
        <div className="scroll-down">
          <div className="line"/>
          <div className="arrows">
            <svg viewBox="0 0 142.3 182.6"><polygon className="cls-1" points="71.2 182.6 0 111.5 16.3 95.2 71.2 150.1 126.1 95.2 142.3 111.5 71.2 182.6"/><path className="cls-1" d="M71.2,119.6,11.3,59.7,71.3,0,131,59.7ZM43.8,59.8,71.2,87.1,98.5,59.7,71.3,32.5Z"/></svg>
          </div>
          <div className="line"/>
        </div>
      </Link>
      
    </div>
  )
}

export default HomePage;