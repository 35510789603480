import { useEffect, useState } from "react"
import ReactMapGL, { Marker } from "react-map-gl"

import contactInfo from "../data/context/contact-page.json"

function ContactPage() {
  const [scroll, setScroll] = useState(false);
  const longLat = {
    latitude: 56.174698,
    longtitude: 22.532211,
  }

  const [viewport, setViewport] = useState({
    latitude: longLat.latitude,
    longitude: longLat.longtitude,
    zoom: 13,
    bearing: 0,
    pitch: 0,
    dragPan: false, 
    touchAction: 'pan-y'
  })

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const contactPage = document.getElementById("contact");
      const contactPosition = contactPage.offsetTop;
      const windowHeight = window.innerHeight;
      const activationOffset = 80;

      const scrollCheck = (windowHeight + window.scrollY) > (contactPosition + activationOffset);
      setScroll(scrollCheck);
    });
  }, [])

  return (
    <>
      <div className={scroll ? "contact-page active" : "contact-page"} id="contact">
        <h2>Kontaktai</h2>

        <div className="contact-info">
          <ul>
            <li>
              <span>Telefono nr.:</span>
              <p><a href={`tel:${contactInfo.phoneNumber}`}>{contactInfo.phoneNumber}</a></p>
            </li>
            <li>
              <span>El. paštas:</span>
              <p><a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></p>
            </li>
          </ul>
          <ul>
            <li>
              <span>Facebook:</span>
              <p><a href={`${contactInfo.facebook}`}>{contactInfo.facebook}</a></p>
            </li>
            <li>
              <span>Adresas:</span>
              <p>{contactInfo.address}</p>
            </li>
          </ul>
        </div>

        <div className="map-container">
          <ReactMapGL
            {...viewport}
            width="100%"
            height="100%"
            // mapStyle="mapbox://styles/mapbox/streets-v11"
            mapStyle="mapbox://styles/esujustinas/ckkwtip1410tz17mp8efm50jm"
            mapboxApiAccessToken="pk.eyJ1IjoiZXN1anVzdGluYXMiLCJhIjoiY2tnNnNpeHpvMDBpeTJxcW44YXlsNm1jaSJ9.fx-MhdpBOZ8QUYj5T2qKTg"
            onViewportChange={(view) => setViewport(view)}            
            // dragPan={false}
            // touchAction='pan-y'
          >
            <Marker latitude={56.1744} longitude={22.532211} onClick={() => setViewport({
                                                                                          ...viewport,
                                                                                          latitude: longLat.latitude,
                                                                                          longitude: longLat.longtitude,
                                                                                        })}>
              <div className="marker"><span></span></div>
            </Marker>
          </ReactMapGL>
        </div>
      </div>
    </>
  )
}

export default ContactPage