import React, { useEffect, useState } from "react";
import Tree from "../data/svg/tree.svg";
import Bar from "../data/svg/bar.svg";

function AboutPage() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const aboutPage = document.getElementById("about");
      const aboutPosition = aboutPage.offsetTop;
      const windowHeight = window.innerHeight;
      const activationOffset = 80;

      const scrollCheck = (windowHeight + window.scrollY) > (aboutPosition + activationOffset);
      setScroll(scrollCheck);
    });
  })

  return (
    <div className={scroll ? "aboutpage active" : "aboutpage"} id="about">
      <h2>Apie</h2>
      <div>
        <img className="bar" src={Bar} alt=""/>
        <p>Pavirvytės dvaras - tai vieta sugrįžti į gamtą, prisiminti savo šaknis ir patirti autentišką žemaitijos dvasią. Čia organizuojami mokymai tradicinių amatų meistrams ar jais tapti siekiantiems asmenims, kultūros vertybių savininkams bei plačiajai visuomenei, numatoma vieta aplinkinių bendruomenių veiklai, turizmui, kultūrinėms kūrybinėms programoms bei renginiams, kūrėjų plenerams, tautinio meno festivaliams, koncertinei veiklai.</p>
        <img className="bar" src={Bar} alt=""/>
      </div>
      
      <img className="tree" src={Tree} alt=""/>
    </div>
  )
}

export default AboutPage;