import React, { useState, useEffect } from "react";

import content from "../data/context/history-page.json"
import useWindowDimensions from "./hooks/WindowDimensions";

function DesktopButtons({ content, activeContent, setActiveContent }) {
  return (
    <>
    <div className="tab-content">
      <h3>{activeContent.name}</h3>
      <h4>{activeContent.year}</h4>
      <div className="content">
        <p>{activeContent.text}</p>
        <div className="wrap">
          <img src={activeContent.img} alt={`content` + activeContent.id}></img>
        </div>
      </div>
    </div>

    <div className="tabs">
      {content.map((element) => {
        return (
          <button 
            className={activeContent.id === element.id ? "tab-link active" : "tab-link"} 
            key={element.id}
            onClick={() => setActiveContent(element)}>
              <h3>{element.name}</h3><h4>{element.year}</h4>
          </button>
        )
      })}
    </div>
    </>
  )
}

function Content({ id, content, image }) {
  useEffect(() => {
    const historyPage = document.getElementById("his-container");
    const historyPosition = historyPage.offsetTop;
    const elementRect = document.getElementById(id).getBoundingClientRect();
    const headerOffset = 80;
    const scrollTop = document.documentElement.scrollTop;

    if (window.pageYOffset + headerOffset  > historyPosition) {
      const offsetPosition = elementRect.top + scrollTop - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }, [content, id])

  return (
    <div className="content">
      <p>{content}</p>
      <div className="wrap">
        <img src={image} alt={`content` + id}></img>
      </div>
    </div>
  );
}

function MobileView({ content, activeContent, setActiveContent }) {
  return (
    <>
      {content.map(element => {
        return (
          <div 
            className="tab-content" 
            id={element.id}
            key={element.id}
            onClick={() => activeContent.id !== element.id && setActiveContent(element)}
            >
              <h3>{element.name}</h3>
              <h4>{element.year}</h4>
            { activeContent.id === element.id && <Content id={element.id} content={activeContent.text} image={activeContent.img}/> }
          </div>
        )
      })}
    </>
  )
}

function HistoryTabPage() {
  const [activeContent, setActiveContent] = useState(content[0]);
  const { width } = useWindowDimensions();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const page = document.getElementById("history");
      const pagePosition = page.offsetTop;
      const windowHeight = window.innerHeight;
      const activationOffset = 80;

      const scrollCheck = (windowHeight + window.scrollY) > (pagePosition + activationOffset);
      setScroll(scrollCheck);
    });
  })

  return(
    <div className={scroll ? "history-tab-page active" : "history-tab-page"} id="history">
      <h2>Istorija</h2>
      <div className="history-tab-container" id="his-container">
        { width > 950 ? 
          <DesktopButtons content={content} activeContent={activeContent} setActiveContent={setActiveContent}/> :
          <MobileView content={content} activeContent={activeContent} setActiveContent={setActiveContent}/>}
      </div>
    </div>
  );
} 

export default HistoryTabPage