import React, { useEffect, useState } from "react";
import ZigZag from "../../data/svg/zigzag2.svg";
import ZigZag2 from "../../data/svg/zigzag22.svg";
import Sun from "../../data/svg/navstart2.svg";
import LogoImage from "../../data/svg/logo.svg";
import { Link } from "react-scroll"

function NavBar() {
  const SCROLL_SPEED = 800;
  const [burgerClick, setBurgerClick] = useState(false);
  const [scroll, setScroll] = useState(false);
  const sunStyle = {
    backgroundImage: `url(${Sun})`
  };
  const zigzagStyle = {
    backgroundImage: `url(${ZigZag})`
  };
  const zigzagStyle2 = {
    backgroundImage: `url(${ZigZag2})`
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 80;
      setScroll(scrollCheck)
    });
  })

  return (
    <>
    <nav className={scroll ? "active" : ""}>
      <div className="zigzag" style={zigzagStyle2}/>
      <div className="sun" style={sunStyle}/>
      <ul className={burgerClick ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link 
            activeClass="active"
            to="aboutpage"
            spy={true}
            smooth={true}
            duration={SCROLL_SPEED}
            onClick={() => {burgerClick && setBurgerClick(false)}}
            >Apie</Link>
        </li>
        <li>
          <Link 
            activeClass="active"
            to="history-tab-page"
            spy={true}
            smooth={true}
            duration={SCROLL_SPEED}
            onClick={() => {burgerClick && setBurgerClick(false)}}
            >Istorija</Link>
        </li>
        <li>
          <Link 
            activeClass="active"
            to="gallery-page"
            spy={true}
            smooth={true}
            duration={SCROLL_SPEED}
            onClick={() => {burgerClick && setBurgerClick(false)}}
            >Galerija</Link>
        </li>
        <li>
          <Link 
            activeClass="active"
            to="contact-page"
            spy={true}
            smooth={true}
            duration={SCROLL_SPEED}
            onClick={() => {burgerClick && setBurgerClick(false)}}
            >Kontaktai</Link>
        </li>
      </ul>
      <div className="sun" style={sunStyle}/>
      <div className="zigzag" style={zigzagStyle}/>
      <Link 
          activeClass="active"
          to="homepage"
          spy={true}
          smooth={true}
          duration={SCROLL_SPEED}
          onClick={() => {burgerClick && setBurgerClick(false)}}>
          <img src={LogoImage} alt="small-logo"/>
        </Link>
      <div className={burgerClick ? "burger active" : "burger"} onClick={() => setBurgerClick(!burgerClick)}>
        <div/>
        <div/>
        <div/>
      </div>
    </nav>
    <div className={scroll ? "nav-border active" : "nav-border"}/>
    </>
  );
}

export default NavBar;